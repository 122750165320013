#home {
    position: relative;
    background: url("../../assets/bgIMG.png");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;
        

        .copyright {
            display:none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;

    }
    @media screen and (max-width: 1300px) {
        flex-direction: column;        
    }
    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
        
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width:100%;
        margin-right:0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        background-color: rgba(255,255,255,0.5);

        box-shadow: 0 0 20px rgba(0,0,0,0.1);

    }
    .intro {
        p {
            font-size: 0.92rem;
        }
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            font-size: 1rem;
            text-align: right;
        }
    }
    .tag-cmp:last-child {
        flex-direction: column;
        @media screen and (min-width: 1300px) {
            position:absolute;
            bottom: 4rem;
        }

        

        p {
            width: 100%;
            font-size: 1rem;
            text-align: left;
        }
    }


    span {
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }

    }

    @media screen and (max-width: 1300px) {
        margin-left: 2%;
    }
    @media screen and (max-width: 800px) {
        margin-left: 0;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 1rem 2rem;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 0.5rem;

    @media screen and (max-width: 1300px) {
        display: none;
    }

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(--white-color);

        box-shadow: 0 0 20px rgba(0,0,0,0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 125px;
        height: 125px;
    }
    div:nth-child(2) {
        margin: 1.75rem;
        width: 175px;
        height: 175px;
    }
    div:nth-child(3) {
        width: 100px;
        height: 100px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width: 300px;
            height: 300px;
        }
        div:nth-child(2) {
            margin: 1.75rem;
            width: 400px;
            height: 400px;
        }
        div:nth-child(3) {
            width: 250px;
            height: 250px;
        }
    }

    @media screen and (max-width: 1300px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

.p-text {
    a {
        color: var(--gray-color);
        transition: ease-in-out;
        transition-duration: 0.2s;

        &:hover {
            color: var(--secondary-color);
            
        }
    }

}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;


    }

    @media screen and (max-width: 1300px) {
        //margin: 2rem 0;
        margin-top: -55%;
        margin-right: 40%;
        margin-left: -15%;
    }
    @media screen and (max-width: 800px) {
        margin: 2rem 0;

    }

}