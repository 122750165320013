.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;

}

.app__skills-container {
    width: 80%;
    height: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    
    @media screen and (max-width: 1100px) {
        width: 100%;
        flex-direction: column;

    }
}

.app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: -8rem;

    margin-right: 5rem;

    @media screen and (max-width: 1100px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;

    }
    
}

.app__skills-item {
    flex-direction: column;
    text-align: center;

    margin: 1.25rem;
    transition: all 0.3s ease-in-out;
    div {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;

        img {
            width: 60%;
            height: 60%;
        }

        &:hover {
            box-shadow: 0 0 25px #fef4f5;
        }

        @media screen and (min-width: 2000px) {
            width: 150px;
            height: 150px;

        }
        @media screen and (max-width: 450px) {
            width: 70px;
            height: 70px;
            
        }
    }

    p {
        font-weight: 500;
        margin-top: 0.5rem;

    }

    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p {
            margin-top: 1rem;
        }
    }
}

.app__skills-exp {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: -2rem;
    @media screen and (max-width: 1100px) {
        margin-top: 2rem;

    }


}

.arrowcontainer h4:first-of-type {
    float: left;
    margin-right: 0.7rem;
}

.arrowcontainer h4:last-of-type {
    float: right;
}

.rotation {
    transform: rotate(90deg);
    color: var(--secondary-color);
}

.app__skills-exp-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    margin: 1rem 0;
}

.app__skills-exp-works {
    flex: 1;
    
    .app__skills-exp-work {
        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        align-items: flex-start;

        margin-bottom: 0.5rem;

        cursor: pointer;

        h4 {
            font-weight: 500;
            font-size: 1.2rem;
            

        }

        
            
        p {
            font-weight: 500;
            font-size: 1.05rem;
            color: var(--gray-color);
            margin-top: 5px;
        }
        
    }
}

.p-desc {
    font-weight: 400 !important;
    font-size: 0.95rem !important;
}

.list {
    margin-left: 1rem;
    li {
        margin-top: 0.2rem;
    }
}

.app__skills-exp-year {
    margin-right: 3rem;
    width: 3rem;
    p {
        font-weight: 800;
        color: var(--secondary-color);
        font-size: 1.2rem;
    }

    @media screen and (max-width: 450px) {
        margin-right: 1rem;

    }
}

.unbolded {
    font-weight: 400;
}