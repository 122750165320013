.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;

}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-wrap: wrap;
    margin-top: 2rem;

    
    
}


.app__profile-item {
    width: 215px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;

    img {
        width: 100%;
        height: 210px;
        border-radius: 15px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;

        img {
            height: 320px;
        }
    }
    
}



.head-text {
    text-transform: none !important;
}

.vertical-line {
    display: inline-block;
    border-left: 2px solid #ccc;
    margin: 3rem 2rem;
    height: 22rem;

}